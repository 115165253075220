body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

ol, ul {
  padding-left: 30px;
}

.kbd-navigation .square:focus {
  background: #ddd;
}
